import { createSelector } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { ISeasonDTO, ISeasonMetricDataDTO } from '@halter-corp/cattle-breeding-service-client';
import { AppState } from '../store';
import { selectCattle } from './cattle.selectors';

const selectBehaviour = createSelector(
  (state: AppState) => state.behaviour,
  (behaviour) => behaviour
);

export const selectFarmHeats = createSelector(selectBehaviour, (behaviour) => behaviour.farmHeats);
export const selectCowHeats = createSelector(selectBehaviour, (behaviour) => behaviour.cowHeats);
export const isHeatsLoading = createSelector(
  selectBehaviour,
  (behaviour) => behaviour.heatsLoadingStatus === 'loading'
);

export const selectSeasonMetrics = createSelector(selectBehaviour, (behaviour) => behaviour.seasonMetrics);
export const selectSeasonMetricsSummary = createSelector(
  selectBehaviour,
  (behaviour) => behaviour.seasonMetricsSummary
);

export const selectTaggedCowsInSeasonMetrics = createSelector(
  selectCattle,
  selectSeasonMetrics,
  (cows, season): ISeasonMetricDataDTO => {
    const {
      abCattleIds = [],
      notAbCattleIds = [],
      nonReturnedCattleIds = [],
      eligibleInseminations = 0,
    } = season?.abData ?? {};
    const { cycledCattleIds = [], notCycledCattleIds = [] } = season?.heatData ?? {};

    const keyCowsById = keyBy(cows, 'id');

    return {
      heatData: {
        cycledCattleIds: cycledCattleIds.filter((id) => keyCowsById[id] != null),
        notCycledCattleIds: notCycledCattleIds.filter((id) => keyCowsById[id] != null),
        averageDaysToFirstHeat: 0,
        cattleIdsCycledSinceCalving: [],
      },
      abData: {
        eligibleInseminations,
        notAbCattleIds,
        abCattleIds,
        nonReturnedCattleIds,
      },
    };
  }
);

export const selectSeason = createSelector(selectBehaviour, (behaviour) => behaviour.season);

export const selectTaggedCowsInSeason = createSelector(
  selectCattle,
  selectSeason,
  (cows, season): ISeasonDTO | undefined => {
    const keyCowsById = keyBy(cows, 'id');
    if (season == null) return undefined;
    return {
      ...season,
      includedCattleIds: season?.includedCattleIds.filter((id) => keyCowsById[id] != null) ?? [],
    };
  }
);

export const selectFarmCurrentHealthCases = createSelector(
  selectBehaviour,
  (behaviour) => behaviour.farmCurrentHealthCases
);

export const selectFarmHistoricalHealthCases = createSelector(
  selectBehaviour,
  (behaviour) => behaviour.farmHistoricalHealthCases
);

export const selectCowHealthCases = createSelector(selectBehaviour, (behaviour) => behaviour.cowHealthCases);

export const selectMindaIntegrationStatus = createSelector(
  selectBehaviour,
  (behaviour) => behaviour.mindaIntegrationStatus
);

export const selectCalvingInfo = createSelector(selectBehaviour, (behaviour) => behaviour.calvingInfo);
export const isCalvingInfoLoading = createSelector(
  selectBehaviour,
  (behaviour) => behaviour.calvingInfoLoadingStatus === 'loading'
);
export const selectCalvingSummary = createSelector(selectBehaviour, (behaviour) => behaviour.calvingSummary);

export const selectInterventions = createSelector(selectBehaviour, (behaviour) => behaviour.interventions);
export const isInterventionsLoading = createSelector(
  selectBehaviour,
  (behaviour) => behaviour.interventionsLoadingStatus === 'loading'
);
